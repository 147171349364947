<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->
            <form class="needs-validation" @submit.prevent="formFinish">
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="branchId">สาขา:</label>
                        <multiselect
                          id="branchId"
                          v-model="form.branchId"
                          :options="localData"
                          class=""
                          track-by="nameTh"
                          label="nameTh"
                          :show-labels="false"
                          @input="handleSearch"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-5">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="svId"> เลือกจากเลขที่เข้ารับบริการ:</label>
                        <!-- disabled="disabled"  -->
                        <multiselect
                          id="branchId"
                          v-model="form.svId"
                          :options="rowSv"
                          class=""
                          track-by="svCode"
                          label="svCode"
                          :custom-label="customLabel"
                          :show-labels="false"
                          placeholder="ค้นหาเลขทะเบียนรถ"
                          :class="{
                            'is-invalid': submitform && $v.form.svId.$error,
                          }"
                          @input="onRowSelectedOb(form.svId)"
                          @search-change="getSv($event)"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                        <div
                          v-if="submitform && $v.form.svId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.svId.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-sm-1">
                      <div class="mt-2 position-relative">
                        <b-button class="btn" variant="info" v-b-modal.modalRo>
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="svDate"> วันที่แจ้งซ่อม:</label>

                        <date-picker
                          disabled
                          v-model="form.svDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                        ></date-picker>
                      </div>
                    </div>
                    <!-- <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="delDate"> วันที่ส่งมอบรถ:</label>

                        <date-picker
                          v-model="form.delDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                          :class="{
                            'is-invalid': submitform && $v.form.delDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.form.delDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.delDate.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-6 col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="cusName">ชื่อลูกค้า:</label>

                        <b-form-input
                          disabled
                          id="dcPer"
                          v-model="form.cusName"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-6 col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="licensePlate">ทะเบียนรถ:</label>

                        <b-form-input
                          disabled
                          id="dcPer"
                          v-model="form.licensePlate"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-6 col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="vin">เลขตัวถัง:</label>

                        <b-form-input
                          disabled
                          id="vin"
                          v-model="form.vin"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-6 col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="vehicleBrand">ยี่ห้อรถ:</label>

                        <b-form-input
                          disabled
                          id="vehicleBrand"
                          v-model="form.vehicleBrand"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>
                    <!-- <div class="col-6 col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="colorOut">สีภายนอก</label>

                      <b-form-input
                        disabled
                        id="colorOut"
                        v-model="form.colorOut"
                        type="text"
                        class="form-control"
                        value="Otto"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-6 col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="colorIn">สีภายใน</label>

                      <b-form-input
                        disabled
                        id="colorIn"
                        v-model="form.colorIn"
                        type="text"
                        class="form-control"
                        value="Otto"
                      ></b-form-input>
                    </div>
                  </div> -->
                    <!-- <div class="col-6 col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="baseModel">ชื่อ Base Model</label>

                        <b-form-input
                          id="baseModel"
                          v-model="form.baseModel"
                          type="number"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div> -->
                    <div class="col-6 col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="vehicleModel">รุ่นรถ:</label>

                        <b-form-input
                          disabled
                          id="vehicleModel"
                          v-model="form.vehicleModel"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div class="col-md">
                    <button class="btn btn-success float-end" type="submit">
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <b-modal
      ref="modalRo"
      id="modalRo"
      title="รายการเข้ารับบริการ"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="form.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขที่เข้ารับบริการ:
                  <b-form-input
                    v-model="filter.svCode"
                    type="search"
                    placeholder="เลขที่ใบเข้ารับบริการ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  ทะเบียนรถ:
                  <b-form-input
                    v-model="filter.licensePlate"
                    type="search"
                    placeholder="ทะเบียนรถ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filter.nameTh"
                    type="search"
                    placeholder="ชื่อ-นามสกุล"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  นามสกุลลูกค้า:
                  <b-form-input
                    v-model="filter.familyNameTh"
                    type="search"
                    placeholder="นามสกุลลูกค้า"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="mb-4 position-relative">
                  <label class="d-inline align-items-center m-2">
                    วันที่เข้ารับบริการ</label
                  >

                  <date-picker
                    v-model="filter.svDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    @input="handleSearch"
                    value-type="format"
                    lang="en"
                  ></date-picker>
                </div>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowSv"
              :fields="fields"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
              <!-- <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template> -->

              <!-- End edit table -->
              <template #cell(svDate)="rowSv">
                <changeDate :date="rowSv.item.svDate"></changeDate>
              </template>
            </b-table>
          </div>

          <div class="row">
            <span v-if="this.totalRecord === 0" class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecord > 0">
              <div class="col">
                หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                {{ totalRecord }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecord"
                      :per-page="perPage"
                      @change="handleChangePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <!-- <p>
              <b-button size="sm" @click="selectAllRowsVeh"
                >เลือกทั้งหมด</b-button
              >
              |
              <b-button size="sm" @click="clearSelectedVeh"
                >ล้างการเลือก</b-button
              >
            </p> -->
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>
    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  //   maxValue,
  //   minValue,
  //   maxLength,
  //   numeric,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import DatePicker from "vue2-datepicker";
import ChangeDate from "@/components/changeDateComponent";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    ChangeDate,
  },
  page: {
    title: "บันทึกส่งมอบรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      loading: undefined,
      message: "โปรดตรวจสอบข้อมูล",
      title: "บันทึกส่งมอบรถ",
      items: [
        {
          text: "การเงิน",
          active: true,
        },
        {
          text: "บันทึกส่งมอบรถ",
          active: true,
        },
      ],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      localData: [],
      optionsYear: [],
      filter: {
        svCode: "",
        familyNameTh: "",
        nameTh: "",
        licensePlate: "",
        svDate: "",
      },
      fields: [
        {
          key: "svCode",
          sortable: true,
          label: "เลขที่เข้ารับบริการ",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "familyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "vin",
          sortable: true,
          label: "เลขตัวถัง",
        },
        {
          key: "svDate",
          sortable: true,
          label: "วันที่เข้ารับบริการ",
        },

        {
          key: "distanceNameTh",
          sortable: true,
          label: "ประเภท",
        },

        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      branchIdSearch: "",
      branchIdRo: "",
      rowSv: [],
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      rowTest: [],
      totalRows: 1,
      rowParts: [],
      pageOptions: [5, 10, 25, 50, 100],
      selectMode: "single",
      selected: [],
      isLoading: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionsGroupId: [],
      optionsUnits: [],
      options: [],
      use: [
        { text: "ใช้กับศูนย์ซ่อมสีและตัวถัง", value: "1" },
        { text: "ใช้กับศูนย์บริการ", value: "2" },
      ],
      opcodeType: [
        {
          name: "อะไหล่นอก",
          id: "EX",
        },
        {
          name: "กำหนดเอง",
          id: "CUSTOM",
        },
      ],
      form: {
        branchId: "",
        svId: "",
        roDate: "",
        delDate: "",
        cusName: "",
        licensePlate: "",
        vin: "",
        vehicleBrand: "",
        colorOut: "",
        colorIn: "",
        vehicleModel: "",
        baseModel: "",
        pickUp: "",
      },

      submitform: false,
    };
  },

  validations: {
    form: {
      svId: {
        required,
      },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.form.branchId = { branchId: item.branchId, nameTh: item.nameTh };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    this.getSv();
  },
  created() {
    // this.getLocalData();
    // this.getSv();
  },
  methods: {
    customLabel({ svCode, licensePlate }) {
      return `[${svCode}] - ${licensePlate != null ? licensePlate : ""}`;
    },
    onRowSelected(rowRo) {
      this.selected = rowRo[0];
      var svCode = {
        svCode: this.selected.svCode,
        svId: this.selected.svId,
        licensePlate: this.selected.licensePlate,
      };
      var customerFullNameTh = `${
        this.selected.nameTh != null ? this.selected.nameTh : ""
      } ${
        this.selected.familyNameTh != null ? this.selected.familyNameTh : ""
      }`;
      var licensePlate = this.selected.licensePlate;
      var svDate = this.selected.svDate;
      var branchName = { nameTh: this.selected.branchName };
      this.form.svId = svCode;
      this.form.cusName = customerFullNameTh;
      this.form.licensePlate = licensePlate;
      this.form.svDate = svDate;
      this.form.vin = this.selected.vin;
      this.form.vehicleBrand = this.selected.vehicleBrandTh;
      this.form.vehicleModel = this.selected.vehicleModelTh;
      this.form.branchId = branchName;
      this.$refs["modalRo"].hide();
    },
    onRowSelectedOb(rowRo) {
      this.selected = rowRo;
      var svCode = {
        svCode: this.selected.svCode,
        svId: this.selected.svId,
        licensePlate: this.selected.licensePlate,
      };
      var customerFullNameTh = `${
        this.selected.nameTh != null ? this.selected.nameTh : ""
      } ${
        this.selected.familyNameTh != null ? this.selected.familyNameTh : ""
      }`;
      var licensePlate = this.selected.licensePlate;
      var svDate = this.selected.svDate;
      var branchName = { nameTh: this.selected.branchName };
      this.form.svId = svCode;

      this.form.cusName = customerFullNameTh;
      this.form.licensePlate = licensePlate;
      this.form.svDate = svDate;
      this.form.vin = this.selected.vin;
      this.form.vehicleBrand = this.selected.vehicleBrandTh;
      this.form.vehicleModel = this.selected.vehicleModelTh;
      this.form.branchId = branchName;
      this.$refs["modalRo"].hide();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      // this.getRo(this.branchId);
      // console.log(this.localDataBranchIdUser);
    },
    getSv: function (event) {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get("api/finish-ro/services", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.localDataBranchIdUser,
            svCode: this.filter.svCode,
            nameTh: this.filter.nameTh,
            familyNameTh: this.filter.familyNameTh,
            licensePlate: event != null ? event : this.filter.licensePlate,
            svDate: this.filter.svDate,
          },
        })
        .then((response) => {
          this.rowSv = response.data.data;
          this.svId = response.data.data.svId;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
          // this.overlayFlag = false;
        });
    },
    formFinish() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.putSubmit();
      }
    },
    clearPost() {
      this.form.svId = "";
      this.form.branchId = "";
      this.form.svDate = "";
      this.form.cusName = "";
      this.form.vin = "";
      this.form.licensePlate = "";
      this.form.vehicleBrand = "";
      this.form.vehicleModel = "";
      this.submitform = false;
    },
    putSubmit() {
      useNetw
        .put("api/finish-ro/submit", {
          svId: this.form.svId.svId,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.clearPost();
          this.getSv();
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchIdUser;
      } else {
        this.localDataBranchIdUser = [this.branchIdSearch.branchId];
      }
      this.getSv();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getSv();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getSv();
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped></style>
